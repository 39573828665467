<template>
  <ul class="divide-y" :class="brandClasses.list">
    <li>
      <base-button
        class="w-full flex"
        :class="brandClasses.button"
        data-test-id="remove"
        :disabled="cart.pending"
        @click="$emit('remove')"
      >
        <vf-icon  name="close" :size="iconSize" />
        <span>{{ $t.removeItem }}</span>
      </base-button>
    </li>
    <li v-if="$feature.allowSaveForLater && !hideSaveForLater">
      <base-button
        class="w-full flex"
        :class="brandClasses.button"
        data-test-id="save-for-later"
        :disabled="cart.pending"
        @click="$emit('save-for-later')"
      >
        <vf-icon dir="down" name="arrow" :size="iconSize" />
        <span>{{ $t.saveForLater }}</span>
      </base-button>
    </li>
    <li>
      <base-button
        class="w-full flex"
        :class="brandClasses.button"
        data-test-id="edit"
        :disabled="cart.pending"
        @click="$emit('edit')"
      >
        <vf-icon  name="edit" :size="iconSize" />
        <span>{{ $t.editItem }}</span>
      </base-button>
    </li>
  </ul>
</template>

<script lang="ts" setup>
defineProps<{
  hideSaveForLater?: boolean
}>()

defineEmits<{
  'edit': []
  'remove': []
  'save-for-later': []
}>()

const cart = useCartStore()
const { brandClasses, iconSize } = useAppConfig().pages.cart.item.actionMenu
</script>
